.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.2);
}
