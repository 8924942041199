.Landing {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.Logo {
	display: none;
}

.LogoImage {
	width: 150px;
}
.Lead {
	max-width: 550px;
}
.Landing h1 {
	margin-bottom: 0;
}

.Landing h2 {
	margin-bottom: 30px;
}

.Landing h2 em {
	text-decoration: line-through;
	font-style: normal;
}

.FormGroup {
	align-items: center;
	margin: 20px;
}

.FormControl {
	height: 56px;
	width: 275px;
}
.FormControl::placeholder {
	font-family: 'SourceBold';
}

.InputGroupText {
	background-color: #f7fafc !important;
}

button {
	margin: 10px;
}

@media (min-width: 1500px) {
	.Landing {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 30px;
		text-align: right;
		padding: 20px 400px;
	}
	button {
		margin: 10px 0;
	}
}

@media (min-width: 770px) and (max-width: 1500px) {
	.Landing {
		padding: 20px 100px;
	}
}
