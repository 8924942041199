.Portafolio {
  background: var(--primary-color);
  display: flex;
  flex-flow: column;
}

.Portafolio h1 {
  color: var(--white-color);
}

.Portafolio ul li {
  clear: both;
  height: 100%;
}

@media (min-width: 1000px) {
  .Portafolio {
    padding: 30px auto;
  }
  .List {
    display: flex;
    justify-content: space-around;
  }
}
