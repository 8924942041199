.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}

.NavigationItems ul {
  margin: 0;
  padding: 0;
}

@media (min-width: 1000px) {
  .NavigationItems ul {
    display: flex;
    flex-flow: row;
  }
}

@media (max-width: 499px) {
  .NavigationItems {
    margin-bottom: 30px;
  }
}
