.Toolbar {
  position: fixed;
  height: 56px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 999px) {
  .DesktopOnly {
    display: none;
  }
}

@media (min-width: 1000px) {
  .Toolbar {
    align-items: center;
    padding: 0;
  }
}
