.Aftermath img {
  width: 150px;
  height: 20px;
}

@media (min-width: 1000px) {
  .Aftermath {
    display: none;
  }
}
