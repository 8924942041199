.Footer {
  display: flex;
  flex-flow: column;
  margin-top: 20px;
}

.Footer h3 {
  text-align: center;
  font-size: 16px;
}

.Footer img {
  margin: 5px;
}

.Redes {
  display: flex;
  justify-content: center;
}
