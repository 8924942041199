.Servicios {
  display: flex;
  flex-flow: column;
}

.Servicio h1 {
  font-size: 36px;
}

.Icono img {
  margin: 30px;
}

@media (min-width: 1000px) {
  .Servicios {
    flex-flow: row;
    padding: 30px 50px;
  }

  .Servicio {
    padding: 0 50px;
  }
}
