.Logo {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.Logo img {
	display: none;
}
@media (min-width: 1000px) {
	.Logo img {
		display: block;
		width: 190px;
	}
}
